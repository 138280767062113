export const OWN_USER = "ownUser";
export const USER_FLOWS = "userFlows";
export const USER_FLOW_DEFINITIONS = "userFlowsDefinitions";
export const FLOW = "flow";
export const PROVIDER_USERS = "providerUsers";
export const PROVIDER_USER_FLOWS = "providerUserFlows";
export const PROVIDER_FLOW_PRESETS = "providerFlowPresets";
export const PROVIDER_FLOWS = "providerFlows";
export const SUPER_ADMIN_PROVIDERS = "superAdminProviders";
export const SUPER_ADMIN_USERS = "superAdminUsers";
export const SUPER_ADMIN_FILES = "superAdminFiles";

export default {
    OWN_USER,
    USER_FLOWS,
    USER_FLOW_DEFINITIONS,
    FLOW, PROVIDER_USERS,
    PROVIDER_USER_FLOWS,
    PROVIDER_FLOW_PRESETS,
    PROVIDER_FLOWS,
    SUPER_ADMIN_PROVIDERS,
    SUPER_ADMIN_USERS,
    SUPER_ADMIN_FILES
};