import React, { useEffect, useState, useRef } from "react";
import colors from "../utils/colors";
import { RiEdit2Line, RiSave2Line, RiCloseLine } from "react-icons/ri";

export default ({ title, icon, value, style, titleStyles, valueStyles, children, editable, onValueSaved, onCancel, onClick }) => {
    const inputRef = useRef(null);

    const [editMode, setEditMode] = useState(false);
    const [newValue, setNewValue] = useState(value);

    useEffect(() => {
        if (inputRef && editMode) inputRef?.current.focus();
    }, [editMode]);

    const _onValueSaved = () => {
        onValueSaved && onValueSaved(newValue);
        setEditMode(false);
    }

    const _onCancel = () => {
        setNewValue(value);
        setEditMode(false);
        onCancel && onCancel();
    }

    return (
        <div style={{
            backgroundColor: "white",
            paddingLeft: 24,
            paddingTop: 20,
            paddingBottom: 30,
            paddingRight: 24,
            flex: 1,
            borderRadius: 8,
            marginRight: 30,
            position: "relative",
            ...style
        }}
            onClick={onClick}
            className="shadow"
        >
            {editable && !editMode && <RiEdit2Line onClick={() => setEditMode(!editMode)} size={24} style={{ position: "absolute", right: 16, top: 16, cursor: "pointer" }} />}
            {editable && editMode && <RiSave2Line onClick={_onValueSaved} size={24} style={{ position: "absolute", right: 48, top: 16, cursor: "pointer" }} />}
            {editable && editMode && <RiCloseLine onClick={_onCancel} size={24} style={{ position: "absolute", right: 16, top: 16, cursor: "pointer" }} />}
            {title && <div style={{ fontSize: 14, fontWeight: 300, color: colors.text, ...titleStyles }}>{title}</div>}
            {value && !editMode && <div style={{ fontSize: 32, fontWeight: 700, color: colors.text, ...valueStyles }}>{value}</div>}
            {editMode && <input ref={inputRef} value={newValue} onChange={(e) => setNewValue(e.target.value)} style={{ all: "unset", fontSize: 32, fontWeight: 700, color: colors.text, ...valueStyles }} />}
            {children}
        </div>);
};
