import React from "react";
import colors from "../utils/colors";
import keys from "../api/keys";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import CreateButton from "../components/CreateButton";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { getUserFlowDefinitions } from "../api/userFlowDefinitions";

export default () => {
    const navigate = useNavigate();
    const { data: userFlowDefinitions } = useQuery([keys.USER_FLOW_DEFINITIONS], () => getUserFlowDefinitions());

    const UserFlowDefinition = ({ userFlowDefinition }) => (
        <Row
            onClick={() => navigate(`flows/${userFlowDefinition.id}`)}
            style={{
                borderRadius: 12,
                backgroundColor: "white",
                cursor: "pointer",
                padding: 30,
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10
            }}
            className="shadow">
            <Col style={{ color: colors.text }}>{`Standard`}</Col>
            <Col style={{ color: colors.grey }}>{`${userFlowDefinition.flows.length} Flows`}</Col>
            <Col style={{ color: colors.grey, textAlign: "right" }}>{`
                ${dayjs(userFlowDefinition.start).format("DD.MM.YYYY")}-${dayjs(userFlowDefinition.end).format("DD.MM.YYYY")}
            `}</Col>
        </Row>
    );

    if (!userFlowDefinitions) return;

    return (<div>
        <div className="d-flex align-items-center" style={{ marginBottom: 30, justifyContent: "space-between" }}>
            <div style={{ color: colors.text, fontSize: 36, }}>Reflexion</div>
            <CreateButton onClick={() => { }} title="Flowplan erstellen" />
        </div>
        {userFlowDefinitions.map(x => <UserFlowDefinition userFlowDefinition={x} />)}
    </div>)
};