import React from "react";
import { Card, Dropdown, DropdownButton } from "react-bootstrap";


export default ({ data, selected, onSelect, label, style, displayProp }) => {
    return (
        <Card className="shadow" style={{ display: "flex", paddingLeft: 16, margin: 0, width: "100%", flexDirection: "row", alignItems: "center", height: 70, border: "none", ...style }}>
            <div style={{ marginLeft: 16, marginRight: 16 }}>{label}</div>
            <DropdownButton title={selected ? selected : "Auswählen"}>
                {data.map(x => <Dropdown.Item onClick={() => onSelect(x)}>{displayProp ? x[displayProp] : x}</Dropdown.Item>)}
            </DropdownButton>
        </Card>
    )
}
