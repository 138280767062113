import React, { useState } from "react";
import colors from "../utils/colors";
import keys from "../api/keys";
import { useMutation, useQuery, useQueryClient } from "react-query";
import GoBackButton from "../components/GoBackButton";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import CreateButton from "../components/CreateButton";
import { useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import { createProvider, getProviderForAdmin } from "../api/admin";
import Card from "../components/Card";
import DropdownInput from "../components/DropdownInput";

export default () => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [parent, setParent] = useState("");

    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation(
        () => createProvider({ name, parentId: parent.id }),
        {
            onSuccess: (_) => {
                queryClient.invalidateQueries(keys.SUPER_ADMIN_PROVIDERS);
                navigate(-1);
            },
            onError: (e) => {
                console.warn(e);
                alert('there was an error');
            },
        }
    );

    const { data: providers } = useQuery([keys.SUPER_ADMIN_PROVIDERS], () => getProviderForAdmin());

    if (!providers) return;

    return (<div>
        <div className="d-flex align-items-center">
            <GoBackButton />
            <div style={{ color: colors.text, fontSize: 36, marginLeft: 8 }}>Provider anlegen</div>
        </div>
        <Form className="col-md-5 col-12" style={{ marginTop: 50 }}>
            <TextInput label={"Name"} onChange={(value) => setName(value)} value={name} />
            <DropdownInput label="Parent" data={providers} selected={parent?.name} onSelect={(value) => setParent(value)} displayProp="name" />
        </Form>
        <CreateButton loading={isLoading} style={{ marginTop: 30 }} variant="primary" onClick={mutate} title="Provider anlegen" />
    </div>)
};