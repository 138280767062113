import React, { useState } from "react";
import { useMutation } from "react-query";
import { login } from "../api/account";
import { Button, Form, Row, Col, Image } from "react-bootstrap";

export default () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { mutate, isLoading } = useMutation(
        () => login(email, password),
        {
            onSuccess: (data) => {
                localStorage.setItem("token", data);
                window.location.href = "/";
            },
            onError: (e) => {
                console.warn(e);
                alert('there was an error');
            },
        }
    );
    
    return (
        <div className="container d-flex justify-content-center">
            <Form className="col-md-5 col-12" style={{ marginTop: 50 }}>
                <div style={{ textAlign: "center", marginBottom: 10 }}>
                    <Image src={require("../assets/icon.png")} height={100} />
                </div>
                <div style={{ fontSize: 20, textAlign: "center" }}>Login - IO Reflexion</div>

                <Row style={{ marginBottom: 5 }}>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Passwort</Form.Label>
                        <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
                    </Form.Group>
                </Row>
                <Button style={{ marginTop: 25 }} variant="primary" onClick={mutate}>Anmelden</Button>
            </Form>
        </div>
    );
};