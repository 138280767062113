import API from "./API";

const prefix = "/api/user/userFlowQuestionAnswer";

export const createUserFlowQuestionAnswer = async (userFlowId, questionId, answer) => {
    return await API.post(`${prefix}`, { userFlowId, questionId, ...answer });
};

export const updateUserFlowQuestionAnswer = async (userFlowQuestionAnswerId, answer) => {
    return await API.put(`${prefix}/${userFlowQuestionAnswerId}`, { ...answer });
};

export const deleteUserFlowQuestionAnswer = async (userFlowQuestionAnswerId) => {
    return await API.delete(`${prefix}/${userFlowQuestionAnswerId}`);
};



