import React from "react";
import Card from "./Card";
import { RiDeleteBin5Line } from "react-icons/ri";
import { AnswerProperties, AnswerTypes } from "../utils/heimdall";
import colors from "../utils/colors";

export default ({ answers, onDelete, onClick, style, active }) => {
    const properties = answers?.filter(x => x.answerTypeId === AnswerTypes.Symbol).map((y, _) => {
        return { value: y.properties.find(x => x.id === AnswerProperties.AnswerSymbol)?.value, text: y.text, id: y.id }
    });

    return (
        <>
            <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", marginTop: 25, marginBottom: 30 }}>
                {properties.map((x, i) => {
                    const icon = JSON.parse(x.value);
                    return <Card
                        onClick={() => onClick(x.id)}
                        key={i}
                        style={{ border: active?.includes(x.id) ? `2px solid ${colors.darkGreen}` : "none", flex: "none", marginRight: 10, marginBottom: 10, paddingTop: 35, textAlign: "center", cursor: onClick ? "pointer" : null, ...style }}
                        title={`${icon?.SymbolProvider?.ProviderName} - ${icon?.Symbol}`}
                        titleStyles={{ fontSize: 14 }}
                        value={x.text}
                        valueStyles={{ fontSize: 12 }}>
                        {onDelete && <RiDeleteBin5Line
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(x.id);
                            }}
                            className="delete"
                            size={18}
                            style={{ position: "absolute", top: 5, right: 5, marginBottom: 10 }}
                        />}

                    </Card>
                }
                )}
            </div>
        </>);
}