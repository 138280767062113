import React, { useState } from "react";
import Card from "../components/Card";
import { DropdownButton, Form, Dropdown } from "react-bootstrap";
import { getOwnUser } from "../api/user";
import keys from "../api/keys";
import { QuestionTypes, QuestionTypesArray } from "../utils/heimdall";
import { useMutation, useQuery, useQueryClient } from "react-query";
import CreateButton from "../components/CreateButton";
import { createQuestionAtProvider } from "../api/provider";

export default ({ onCreated, flowId }) => {
    const { data: user } = useQuery([keys.OWN_USER], () => getOwnUser());
    const [question, setQuestion] = useState({ text: '', type: '' });

    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation(
        () => createQuestionAtProvider(
            {
                questionTypeId: QuestionTypes[question.type],
                questionText: question.text,
                flowId: flowId,
                providerId: user.providerId
            }),
        {
            onSuccess: (_) => {
                queryClient.invalidateQueries([keys.PROVIDER_FLOWS, flowId]);
                onCreated && onCreated();
            },
            onError: (e) => {
                console.warn(e);
                alert('there was an error');
            },
        }
    );

    const QuestionTypeSelection = () => (
        <DropdownButton title={question.type ? question.type : "Auswählen"}>
            {QuestionTypesArray.map(x => <Dropdown.Item onClick={() => setQuestion({ ...question, type: x })}>{x}</Dropdown.Item>)}
        </DropdownButton>
    );

    return (<Card style={{ flexDirection: "row", display: "flex", alignItems: "center", marginRight: 0 }}>
        <div style={{ marginRight: 16 }}>Frage</div>
        <QuestionTypeSelection />
        <Form.Group style={{ marginLeft: 8, height: 42, width: "50%", marginRight: 32 }}>
            <Form.Control
                onChange={(e) => setQuestion({ ...question, text: e.target.value })}
                value={question.text}
                style={{ height: 42 }}
                placeholder="Text"
            />
        </Form.Group>
        <CreateButton loading={isLoading} onClick={mutate} title="Frage hinzufügen" />
    </Card>)
}