import API from "./API";

const prefix = "/api/file";

export const createFile = async (title, file) => {
    var formData = new FormData();
   
    formData.append("file", file);
    formData.append("title", title);
    return await API.post(`${prefix}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
};

export const getFiles = async () => {
    return await API.get(`${prefix}`);
};





