import React, { useState } from "react";
import colors from "../utils/colors";
import { createUserAtProvider } from "../api/provider";
import keys from "../api/keys";
import { useMutation, useQueryClient } from "react-query";
import GoBackButton from "../components/GoBackButton";
import { Form } from "react-bootstrap";
import CreateButton from "../components/CreateButton";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "../components/TextInput";
import { RolesArray, Roles } from "../utils/heimdall";
import DropdownInput from "../components/DropdownInput";

export default () => {
    const navigate = useNavigate();

    const { providerId } = useParams();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState();

    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation(
        () => createUserAtProvider({ firstName, lastName, email, password, roleId: Roles[role], providerId: providerId }),
        {
            onSuccess: (_) => {
                queryClient.invalidateQueries(keys.PROVIDER_USERS);
                navigate(-1);
            },
            onError: (e) => {
                console.warn(e);
                alert('there was an error');
            },
        }
    );

    return (<div>
        <div className="d-flex align-items-center">
            <GoBackButton />
            <div style={{ color: colors.text, fontSize: 36, marginLeft: 8 }}>Benutzer anlegen</div>
        </div>
        <Form className="col-md-5 col-12" style={{ marginTop: 50 }}>
            <TextInput label={"Vorname"} onChange={(value) => setFirstName(value)} value={firstName} />
            <TextInput label={"Nachname"} onChange={(value) => setLastName(value)} value={lastName} />
            <TextInput label={"Email"} onChange={(value) => setEmail(value)} value={email} />
            <TextInput label={"Passwort"} onChange={(value) => setPassword(value)} value={password} type="password" />
            <DropdownInput label="Rolle" data={RolesArray} selected={role} onSelect={(value) => setRole(value)}/>
        </Form>
        <CreateButton loading={isLoading} style={{ marginTop: 30 }} variant="primary" onClick={mutate} title="Benutzer anlegen" />
    </div>)
};