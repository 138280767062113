import React from "react";
import colors from "../utils/colors";
import { useUser } from "../hooks/useUser";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { getProviderUsers, getUserFlowsProvider } from "../api/provider";
import keys from "../api/keys";
import Card from "../components/Card";
import {
    Chart as ChartJS, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { getProviderForAdmin } from "../api/admin";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


export default () => {
    const user = useUser();

    const { data: providers } = useQuery([keys.SUPER_ADMIN_PROVIDERS], () => getProviderForAdmin());

    if (!user || !providers) return <></>;

    const newestProvider = providers.sort((a, b) => new Date(b.created) - new Date(a.created))[0];

    const test = providers.map(x => {
        const date = new Date(x.created);
        date.setHours(0, 0, 0, 0);
        x.created = date;
        return x;
    });

    const groupedUserFlows = groupBy(test
        .sort((a, b) => new Date(a.created) - new Date(b.created)),
        "created");

    const data = [];
    const labels = [];
    Object.keys(groupedUserFlows).forEach(function (key, index) { data.push(groupedUserFlows[key]) });
    Object.keys(groupedUserFlows).forEach(function (key, index) { labels.push(dayjs(key).format("DD.MM.YYYY")) });

    return (
        <div>
            <div style={{ color: colors.text, fontSize: 36 }}>Willkommen zurück, {user.firstName}</div>
            <div style={{ color: colors.text, fontSize: 14 }}>{dayjs().format("dddd DD MMM YYYY")}</div>
            <div style={{ display: "flex", marginTop: 30 }}>
                <Card title="Anzahl Provider" value={providers.length.toString()} />
                <Card title="Neuester Provider" value={`${newestProvider.name}`} />
                <Card title="Letzter Beitritt" value={`${dayjs(newestProvider.created).format("DD.MM.YYYY")}`} />
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: 30 }}>
                <Card style={{ maxHeight: 250 }}>
                    <Bar
                        options={{
                            responsive: true,
                            scales: {

                                y: {
                                    beginAtZero: true,
                                    ticks: {
                                        stepSize: 1
                                    }
                                },
                            },
                            plugins: {
                                legend: {
                                    display: false
                                },
                                title: {
                                    display: true,
                                    text: 'Beitritt Provider',
                                },
                            }
                        }}
                        data={{
                            labels: labels,
                            datasets: [
                                {
                                    label: "Neue Provider",
                                    data: data.map((x) => x.length),
                                    backgroundColor: colors.darkGreen,
                                },
                            ],
                        }}
                    />
                </Card>
            </div>
        </div>)
};