import React, { useEffect, useState } from "react";
import colors from "../utils/colors";
import keys from "../api/keys";
import { useMutation, useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import { Button, Col, Row, Form } from "react-bootstrap";
import { getUserFlow } from "../api/userFlow";
import { getFlow } from "../api/flow";
import { useParams } from "react-router";
import GoBackButton from "../components/GoBackButton";
import { QuestionTypes } from "../utils/heimdall";
import { RiArrowLeftLine, RiArrowRightLine, RiFingerprintLine } from "react-icons/ri";
import Slider from 'rc-slider';
import { QuestionProperties } from "../utils/heimdall";
import SelectAnswers from "../components/SelectAnswers";
import { useRef } from "react";
import Confetti from "react-confetti";
import { useNavigate } from "react-router-dom";
import { createUserFlowQuestionAnswer } from "../api/userFlowQuestionAnswer";

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export default () => {
    const navigate = useNavigate();

    const { flowId, userFlowId, date } = useParams();
    const { data: flow } = useQuery([keys.FLOW, flowId], () => getFlow(flowId));
    const { data: userFlow } = useQuery([keys.USER_FLOWS, userFlowId], () => getUserFlow(userFlowId));

    const [activeQuestion, setActiveQuestion] = useState(null);
    const [index, setIndex] = useState(0);

    const [answers, setAnswers] = useState([]);

    const queryClient = useQueryClient();

    const { mutate: _createUserFlowQuestionAnswer } = useMutation(
        (answer) => createUserFlowQuestionAnswer(userFlow.id, activeQuestion.id, answer),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([keys.USER_FLOWS, userFlowId]);
            },
            onError: (e) => {
                console.warn(e);
                alert('there was an error');
            },
        }
    );

    useEffect(() => {
        if (!userFlow) return;

        const selectAnswers = userFlow.userFlowQuestionAnswers.filter(x => !!x.answerId);
        const groupedSelectAnswers = groupBy(selectAnswers, "questionId");

        const finishedSelectAnswers = [];
        Object.keys(groupedSelectAnswers).forEach(function (key, index) { finishedSelectAnswers.push({ questionId: key, answer: groupedSelectAnswers[key].map(x => x.answerId) }) });

        const otherAnswers = userFlow.userFlowQuestionAnswers.filter(x => !!!x.answerId);
        setAnswers([...otherAnswers.map(x => { return { questionId: x.questionId, answer: x.number || x.text } }), ...finishedSelectAnswers]);
    }, [userFlow]);

    const prepareAnswer = (answer) => {
        if (activeQuestion.questionTypeId === QuestionTypes.Text) return { text: answer };
        if (activeQuestion.questionTypeId === QuestionTypes.Number) return { number: answer };
        if (activeQuestion.questionTypeId === QuestionTypes.Select) return { answerId: answer };
    }

    const onAnswerChange = (answer) => {
        const currentAnswers = answers.filter(x => x.questionId !== activeQuestion.id);
        setAnswers([...currentAnswers, { questionId: activeQuestion.id, answer: answer }]);

        if (activeQuestion.questionTypeId === QuestionTypes.Select) return;
        !!!userFlow.userFlowQuestionAnswers.find(x => x.questionId === activeQuestion.id) && _createUserFlowQuestionAnswer(prepareAnswer(answer));
    };

    const getProperty = (propertyId) => activeQuestion?.properties?.find(x => x.id === propertyId)?.value;
    const answer = answers?.find(x => x.questionId === activeQuestion.id);

    const TextAnswer = () => {
        const ref = useRef(null);

        const [value, setValue] = useState(answer?.answer);

        useEffect(() => {
            if (!answer) return;
            setValue(answer?.answer);
        }, [answer]);

        useEffect(() => {
            if (!ref) return;
            ref?.current?.focus();
        }, []);

        return (
            <Row style={{ justifyContent: "center" }}>
                <Col md={8}>
                    <Form.Control
                        as="textarea"
                        rows={10}
                        value={value}
                        onBlur={(e) => onAnswerChange(e.target.value)}
                        onChange={(e) => setValue(e.target.value)}
                        style={{ border: "none", padding: 15, borderRadius: 12 }}
                        className="shadow"
                        ref={ref}
                    />
                </Col>
            </Row>);
    }

    const NumberAnswer = () => {
        const [value, setValue] = useState(answer?.answer);

        const minValue = Number.parseInt(getProperty(QuestionProperties.MinNumber));
        const maxValue = Number.parseInt(getProperty(QuestionProperties.MaxNumber));

        return (
            <>
                <div style={{ fontSize: 20, marginTop: 16, marginBottom: 16, textAlign: "center" }}>{value ? Number.parseFloat(value)?.toFixed(0) : (maxValue + minValue) / 2}</div>
                <Slider
                    style={{ cursor: "pointer", height: 15 }}
                    onChange={(value) => setValue(value)}
                    onAfterChange={(_value) => onAnswerChange(_value)}
                    value={value}
                    min={minValue}
                    max={maxValue}
                    defaultValue={(maxValue + minValue) / 2}
                    trackStyle={{ backgroundColor: colors.darkGreen, height: 15 }}
                    handleStyle={{ backgroundColor: colors.darkGreen, height: 25, width: 25, opacity: 1, outline: "none", border: "none", top: 4 }}
                    railStyle={{ height: 15 }}
                    dots={true}
                    dotStyle={{ top: 4, outline: "none", border: "none", width: 7, height: 7, borderRadius: "100%" }}
                    activeDotStyle={{ top: 4, outline: "none", border: "none", width: 7, height: 7, borderRadius: "100%" }}
                />
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 24 }}>
                    <div>{minValue}</div>
                    <div>{(maxValue + minValue) / 2}</div>
                    <div>{maxValue}</div>
                </div>
            </>);
    }

    const SelectAnswer = () => {
        const minAnswers = Number.parseInt(getProperty(QuestionProperties.MinAnswers));
        const maxAnswers = Number.parseInt(getProperty(QuestionProperties.MaxAnswers));

        const [value, setValue] = useState(answer?.answer ? answer?.answer : []);

        const onSelectItem = (id) => {

            if (value.includes(id)) {
                const newArray = value.filter(x => x != id);
                setValue(newArray);
                onAnswerChange(newArray);
                return;
            }
             console.warn(value?.length);
            if (value?.length + 1 > maxAnswers) return;
            const newArray = [...value, id];
            setValue(newArray);
            onAnswerChange(newArray);

            !!!userFlow.userFlowQuestionAnswers.find(x => x.questionId === activeQuestion.id && x.answerId === id) && _createUserFlowQuestionAnswer({ answerId: id });
        }

        return (
            <div>
                <div style={{ color: colors.grey, textAlign: "right" }}>{minAnswers} - {maxAnswers} Antworten möglich</div>
                <SelectAnswers answers={activeQuestion.answers} onClick={onSelectItem} active={value} />
            </div>
        );
    }

    const Finish = () => {
        const [shouldExpand, setShouldExpand] = useState(false);
        const [x, setX] = useState(0);
        const [y, setY] = useState(0);

        const [finished, setFinished] = useState(false);

        useEffect(() => {
            if (x > window.innerWidth && y > window.innerHeight) onFinished();
            if (!shouldExpand) {

                return;
            };

            const interval = setInterval(() => {
                if (!shouldExpand) {
                    setX(0);
                    setY(0);
                }
                setX(x => x + 10);
                setY(y => y + 10);
            }, 10);

            if (!shouldExpand) clearInterval(interval);

            return () => {
                clearInterval(interval);
            };

        }, [shouldExpand]);

        useEffect(() => {
            if (!finished) return;

            const timeout = setTimeout(() => {
                setX(0);
                setY(0);
                setFinished(false);
                navigate(-1);
            }, 10000);

            return () => { clearTimeout(timeout); }
        }, [finished]);

        const onFinished = () => setFinished(true);

        return (
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }} onMouseDown={() => setShouldExpand(true)} onMouseUp={() => setShouldExpand(false)}>
                <div style={{ color: colors.grey, textAlign: "right", marginBottom: 16 }}>Lange gedrückt halten</div>
                <div style={{ alignSelf: "center", cursor: "pointer", backgroundColor: colors.darkGreen, width: 150, height: 150, borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <RiFingerprintLine style={{ zIndex: 10 }} size={50} color="white" />
                </div>
                <div style={{ position: "absolute", backgroundColor: colors.darkGreen, borderRadius: "100%", width: x, height: y, transform: "scale(1.5)" }}></div>
                {finished && <><div style={{ fontSize: 36, color: "white", zIndex: 10 }}>DU BIST IM FLOW!</div><Confetti /></>}
            </div>
        )

    }

    useEffect(() => {
        if (!flow) return;
        setActiveQuestion(sortedQuestions[index]);
    }, [index]);

    const onPrevious = () => {
        if (index === 0) return;
        setIndex(index - 1);
    }

    const onNext = () => {
        if (index === sortedQuestions.length - 1) return;
        setIndex(index + 1);
    }

    const someQuestions = flow?.questions?.filter(x => !x.deactivated).sort((a, b) => a.order - b.order);
    const sortedQuestions = [...someQuestions, { questionTypeId: "Finish", text: "Finish" }];

    if (!flow || !activeQuestion || !userFlow) return;

    return (<div style={{ height: "100%" }}>
        <div className="d-flex align-items-center" style={{ marginBottom: 30, justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <GoBackButton />
                <div style={{ color: colors.text, fontSize: 36, marginLeft: 32 }}>{activeQuestion.text}</div>
            </div>
            <div style={{ fontSize: 20, fontWeight: 600, color: colors.grey }}>Flow am {dayjs(date).format("DD.MM.YYYY")}</div>
        </div>
        <div style={{ height: "70%", paddingTop: "5%" }}>
            {activeQuestion.questionTypeId === QuestionTypes.Text && <TextAnswer />}
            {activeQuestion.questionTypeId === QuestionTypes.Number && <NumberAnswer />}
            {activeQuestion.questionTypeId === QuestionTypes.Select && <SelectAnswer />}
            {activeQuestion.questionTypeId === "Finish" && <Finish />}
        </div>
        <div style={{ display: "flex", alignSelf: "flex-end", justifyContent: "space-evenly" }}>
            <Button
                style={{ borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: 40, width: 40, padding: 10 }}
                onClick={onPrevious}
                variant="primary">
                <RiArrowLeftLine size={20} style={{ alignSelf: "center", justifySelf: "center" }} />
            </Button>
            <Button
                style={{ borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center", height: 40, width: 40, padding: 10 }}
                onClick={onNext}
                variant="primary">
                <RiArrowRightLine size={20} style={{ alignSelf: "center", justifySelf: "center" }} />
            </Button>
        </div>

    </div>)
};