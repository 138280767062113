import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import colors from "../utils/colors";


export default ({ menuItems, menuStyle, itemStyle }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const MenuItem = ({ title, active, icon, onClick, itemStyle }) => (
        <div onClick={onClick} style={!active ?
            { paddingLeft: 20, paddingTop: 16, paddingBottom: 16, cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", ...itemStyle } :
            {
                paddingLeft: 20, paddingTop: 16, paddingBottom: 16, cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center",
                backgroundColor: "#F7F7F7", borderRadius: 12, ...itemStyle
            }}>
            {icon && icon(active ? colors.darkGreen : colors.grey)}
            <div style={{ color: active ? colors.darkGreen : colors.grey, fontSize: 14, fontWeight: "600", marginLeft: 16 }}>{title}</div>
        </div>
    );

    return (
        <div style={menuStyle}>
            {menuItems.map(x => {
                return <MenuItem
                    itemStyle={itemStyle}
                    title={x.title}
                    active={location.pathname === x.path}
                    onClick={() => navigate(x.path)}
                    icon={x.icon}
                />
            })}
        </div>
    )
}

