import React from "react";
import colors from "../utils/colors";
import { useUser } from "../hooks/useUser";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { getProviderUsers, getUserFlowsProvider } from "../api/provider";
import keys from "../api/keys";
import Card from "../components/Card";
import {
    Chart as ChartJS, CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

var groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export default () => {
    const user = useUser();

    const { data: providerUsers } = useQuery([keys.PROVIDER_USERS], () => getProviderUsers());
    const { data: providerUserFlows } = useQuery([keys.PROVIDER_USER_FLOWS], () => getUserFlowsProvider());

    if (!user || !providerUsers || !providerUserFlows) return <></>;

    const newestUser = providerUsers.sort((a, b) => new Date(b.created) - new Date(a.created))[0];
    const newestUserFlow = providerUserFlows.length > 0 ? providerUserFlows.sort((a, b) => new Date(b.pointInTime) - new Date(a.pointInTime))[0] : null;
    const finalizedUserFlows = providerUserFlows.length > 0 ? providerUserFlows.filter(x => !!x.finished).length : 0;

    const groupedUserFlows = groupBy(providerUserFlows.sort((a, b) => new Date(a.pointInTime) - new Date(b.pointInTime)), "pointInTime");

    const data = [];
    const labels = [];
    Object.keys(groupedUserFlows).forEach(function (key, index) { data.push(groupedUserFlows[key]) });
    Object.keys(groupedUserFlows).forEach(function (key, index) { labels.push(dayjs(key).format("DD.MM")) });

    return (
        <div>
            <div style={{ color: colors.text, fontSize: 36 }}>Willkommen zurück, {user.firstName}</div>
            <div style={{ color: colors.text, fontSize: 14 }}>{dayjs().format("dddd DD MMM YYYY")}</div>
            <div style={{ display: "flex", marginTop: 30 }}>
                <Card title="Anzahl Benutzer" value={providerUsers.length.toString()} />
                <Card title="Neuester Benutzer" value={`${newestUser.firstName} ${newestUser.lastName}`} />
                <Card title="Letzter Beitritt" value={`${dayjs(newestUser.created).format("DD.MM.YYYY")}`} />
            </div>
            <div style={{ display: "flex", marginTop: 30 }}>
                <Card title="Anzahl User Flows" value={providerUserFlows.length.toString()} />
                <Card title="Finalized User Flows" value={finalizedUserFlows.toString()} />
                <Card title="Letzter User Flow" value={newestUserFlow ? `${dayjs(newestUserFlow.pointInTime).format("DD.MM.YYYY")}` : "Keiner"} />
            </div>
            <div style={{ display: "flex", flexDirection: "row",  marginTop: 30 }}>
                <Card style={{ flex: 2 }}>
                    <Bar
                        options={{
                            responsive: true,
                            scales: {

                                y: {
                                    beginAtZero: true,
                                    ticks: {
                                        stepSize: 1
                                    }
                                },
                            },
                            plugins: {
                                legend: {
                                    display: false
                                },
                                title: {
                                    display: true,
                                    text: 'User Flows pro Tag',
                                },
                            }
                        }}
                        data={{
                            labels: labels,
                            datasets: [
                                {
                                    label: "User Flows",
                                    data: data.map((x) => x.length),
                                    backgroundColor: colors.darkGreen,
                                },
                            ],
                        }}
                    />
                </Card>
                <Card style={{ flex: 1 }}>
                    <Pie
                        options={{
                            responsive: true,
                        }}
                        data={{
                            labels: ['Check-In', 'Check-Out'],
                            datasets: [
                                {
                                    data: [12, 19],
                                    backgroundColor: [
                                        colors.lightGreen,
                                        colors.darkGreen,
                                    ],
                                    borderColor: [
                                        colors.lightGreen,
                                        colors.darkGreen,
                                    ],
                                    borderWidth: 1,
                                },
                            ],
                        }} />
                </Card>

            </div>
        </div>)
};