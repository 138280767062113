const environment = process.env.NODE_ENV;

const config = {
  ApiUrl: "",
  WebUrl: "",
  environment: "",
};

if (environment === "development") {
  config.ApiUrl = "http://localhost:59502";
  config.environment = "development";
} else if (environment === "production") {
  config.ApiUrl = "https://api-demo.io-reflexion.de";
  config.environment = "production";
} else {
  console.error("NODE_ENV is wrong, It is neither development or production.");
}

export default config;
