import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-slider/assets/index.css';
import 'react-day-picker/dist/style.css';
import './App.css';

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './shared/Login';
import ProviderAdminDashboard from './provideradmin/ProviderAdminDashboard';
import { useUser } from "./hooks/useUser";
import SuperAdminDashboard from './superadmin/SuperAdminDashboard';
import { ToastContainer } from 'react-toastify';
import MemberDashboard from './member/MemberDashboard';
import { Roles } from './utils/heimdall';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de')

const externalRouter = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
]);

const providerAdminRouter = createBrowserRouter([
  {
    path: "*",
    element: <ProviderAdminDashboard />,
  }
]);

const superAdminRouter = createBrowserRouter([
  {
    path: "*",
    element: <SuperAdminDashboard />,
  }
]);

const memberRouter = createBrowserRouter([
  {
    path: "*",
    element: <MemberDashboard />,
  }
]);

const AppSetup = () => {
  const user = useUser();

  const isSuperAdmin = user?.providerId === "00000000-0000-0000-0000-000000000000";
  const isProviderAdmin = user?.roleId === Roles.Admin;
  return <>{user ? <RouterProvider router={isSuperAdmin ? superAdminRouter : isProviderAdmin ? providerAdminRouter : memberRouter} /> : <RouterProvider router={externalRouter} />}</>;
};

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AppSetup />
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
