import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { RiAddFill } from "react-icons/ri";


export default ({ title, onClick, loading, style }) => (
    <Button
        style={{ fontWeight: 600, ...style }} disabled={loading} onClick={onClick} variant="primary">
        {!loading && <><RiAddFill size={20} style={{ marginRight: 8 }} />{title}</>}
        {loading && <div style={{ display: "flex", alignItems: "center" }}>{title}<Spinner size="sm" style={{ marginLeft: 5 }} /></div>}
    </Button>
);