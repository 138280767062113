import React from "react";
import colors from "../utils/colors";
import keys from "../api/keys";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import CreateButton from "../components/CreateButton";
import { useNavigate } from "react-router-dom";
import { getProviderForAdmin } from "../api/admin";
import { Col, Row } from "react-bootstrap";

export default () => {
    const navigate = useNavigate();

    const Provider = ({ provider }) => (
        <Row
            style={{
                borderRadius: 12,
                backgroundColor: "white",
                cursor: "pointer",
                padding: 30,
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10
            }}
            onClick={() => navigate(`provider/${provider.id}`)}
            className="shadow">
            <Col style={{ color: colors.text }}>{`${provider.name}`}</Col>
            <Col style={{ color: colors.grey }}>{`${provider.parent ? "" : "Keine höheren Provider"}`}</Col>
            <Col style={{ color: colors.grey, textAlign: "right" }}>{`Beigetreten am: ${dayjs(provider.created).format("DD.MM.YYYY")}`}</Col>
        </Row>
    );

    const { data: providers } = useQuery([keys.SUPER_ADMIN_PROVIDERS], () => getProviderForAdmin());
    
    if (!providers) return;

    return (<div>
        <div className="d-flex align-items-center" style={{ marginBottom: 30, justifyContent: "space-between" }}>
            <div style={{ color: colors.text, fontSize: 36, }}>Provider</div>
            <CreateButton onClick={() => navigate("create-provider")} title="Provider anlegen" />
        </div>
        {providers.map((x) => <Provider provider={x} />)}
    </div>)
};