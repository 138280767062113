import React from "react";
import colors from "../utils/colors";
import { getProviderUsers } from "../api/provider";
import keys from "../api/keys";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import CreateButton from "../components/CreateButton";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Roles } from "../utils/heimdall";

export default () => {
    const navigate = useNavigate();

    const User = ({ user }) => (
        <Row
            style={{
                borderRadius: 12,
                backgroundColor: "white",
                cursor: "pointer",
                padding: 30,
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10
            }}
            className="shadow">
            <Col style={{ color: colors.text }}>{`${user.firstName} ${user.lastName}`}</Col>
            <Col style={{ color: colors.grey }}>{`${user.email}`}</Col>
            <Col style={{ color: colors.grey }}>{`${user.roleId === Roles.Admin ? "Admin" : "Member"}`}</Col>
            <Col style={{ color: colors.grey, textAlign: "right" }}>{`Beigetreten am: ${dayjs(user.created).format("DD.MM.YYYY")}`}</Col>
        </Row>
    );

    const { data: providerUsers } = useQuery([keys.PROVIDER_USERS], () => getProviderUsers());

    if (!providerUsers) return;

    return (<div>
        <div className="d-flex align-items-center" style={{ marginBottom: 30, justifyContent: "space-between" }}>
            <div style={{ color: colors.text, fontSize: 36, }}>Nutzer</div>
            <CreateButton onClick={() => navigate("create-user")} title="Benutzer anlegen" />
        </div>
        {providerUsers.map((x) => <User user={x} />)}
    </div>)
};