import React, { useEffect, useState } from "react";

import { de } from "date-fns/locale";
import { DayPicker } from "react-day-picker";

export default ({ style, onSelectionChanged }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if(!selected) return;
    onSelectionChanged && onSelectionChanged(selected);
  },[selected])

  return (
    <DayPicker
      mode="single"
      selected={selected}
      onSelect={setSelected}
      weekStartsOn={1}
      locale={de}
      style={{
        backgroundColor: "white",
        padding: 25,
        borderRadius: 12,
        ...style,
      }}
    />
  );
};
