import API from "./API";

const prefix = "/api/provider";

export const getProviderUsers = async () => {
    return await API.get(`${prefix}/user`);
};

export const getUserFlowsProvider = async () => {
    return await API.get(`${prefix}/userFlow`);
};

export const getProviderFlowPresets = async () => {
    return await API.get(`${prefix}/flowPreset`);
};

export const getProviderFlows = async () => {
    return await API.get(`${prefix}/flow`);
};

export const getDetailedFlow = async (id) => {
    return await API.get(`/api/user/flow/${id}`);
};

export const createUserAtProvider = async (data) => {
    return await API.post(`${prefix}/user`, data);
};

export const createAnswerAtProvider = async (data) => {
    return await API.post(`${prefix}/answer`, data);
};

export const deleteAnswerAtProvider = async (providerId, answerId) => {
    return await API.delete(`${prefix}/${providerId}/answer/${answerId}`);
};

export const deleteQuestionAtProvider = async (providerId, questionId, flowId) => {
    return await API.delete(`${prefix}/${providerId}/question/${questionId}/flow/${flowId}`);
};

export const createQuestionAtProvider = async (data) => {
    return await API.post(`${prefix}/question`, data);
};

export const updateQuestionPropertyAtProvider = async (questionId, propertyId, data) => {
    return await API.put(`${prefix}/question/${questionId}/property/${propertyId}`, data);
};





