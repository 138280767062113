import axios from "axios";
import config from "../config";

const API = axios.create({
  responseType: "json",
});

API.interceptors.request.use(async (request) => {
  request.baseURL = config.ApiUrl;
  request.headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  return request;
});

API.interceptors.response.use(
  (response) => {
    return response?.data?.resultObject;
  },
  (error) => {
    let status = error.response.status;
    const errors = error.response.data;

    if (status === 500) {
      alert("Some problems with the server :(");
    }

    if (status === 401) {
      
    } else {
      return errors || error.response.data;
    }
  }
);

export default API;
