export const Roles = {
    "Admin": "1cac28e9-fd03-4bd3-0363-08daec191cef",
    "Member": "7c1c918f-293a-4c07-0365-08daec191cef"
}

export const RolesReversed = {
    "1cac28e9-fd03-4bd3-0363-08daec191cef": "Admin",
    "7c1c918f-293a-4c07-0365-08daec191cef": "Member",
}

export const RolesArray = [
    "Admin",
    "Member"
];

export const Recap = {
    CheckIn: "55665644-e743-4a7a-ba80-08b12102db97",
    CheckOut: "2ff22d5a-da36-4d8a-a8ac-a64a89878986",
    WeeklyReflection: "e8c1e8c1-06ea-4790-b71a-a4a2f01ed6d1"
}

export const QuestionTypes = {
    Number: "e5c01614-cc1e-4b3a-98db-85c8ba672955",
    Select: "99adcf90-6e3a-4807-93e1-d9766e4ff1f5",
    Text: "0a3bbe43-0e19-4c39-8ebf-aec3b2c85ceb",
    Image: "3e58cc5e-dbc6-40d7-88c6-d49156e02c22",
    Recap: "3cf18e86-a3e1-4b88-817a-bc12e8c684eb"
};

export const QuestionTypesArray = [
    "Number",
    "Select",
    "Text",
    "Image",
    "Recap"
];

export const QuestionTypesReversed = {
    "e5c01614-cc1e-4b3a-98db-85c8ba672955": "Number",
    "99adcf90-6e3a-4807-93e1-d9766e4ff1f5": "Select",
    "0a3bbe43-0e19-4c39-8ebf-aec3b2c85ceb": "Text",
    "3e58cc5e-dbc6-40d7-88c6-d49156e02c22": "Select",
    "3cf18e86-a3e1-4b88-817a-bc12e8c684eb": "Recap"
}

export const QuestionProperties = {
    MinAnswers: "c5029667-797d-4dca-84c4-406074e6a730",
    MaxAnswers: "a45d5976-97e6-4049-b26c-9b1368d188b5",
    MaxNumber: "9a44f1df-3651-48bb-83c7-8f860f57009d",
    MinNumber: "68ac9835-9e33-4401-8111-15c806d1fda2",
    Design: {
        Id: "91359310-ed56-471d-8493-60f5b8f40bf9",
        Smiley: "Smiley",
        Weather: "Weather",
        Stresslevel: "Stresslevel"
    }
}

export const QuestionPropertiesReversed = {
    "c5029667-797d-4dca-84c4-406074e6a730": "MinAnswers",
    "a45d5976-97e6-4049-b26c-9b1368d188b5": "MaxAnswers",
    "9a44f1df-3651-48bb-83c7-8f860f57009d": "MaxNumber",
    "68ac9835-9e33-4401-8111-15c806d1fda2": "MinNumber",
    "91359310-ed56-471d-8493-60f5b8f40bf9": "Design",
}

export const AnswerTypes = {
    Symbol: "bbe43cc9-f722-46a2-abd5-cdc5a7dfbcef",
    Text: "561d8016-c337-4f2e-9d38-37b41c8c67e0"
}

export const AnswerProperties = {
    AnswerSymbol: "68000b25-213c-4266-b7f2-c7bef0a6d07d"
}

