import dayjs from "dayjs";
import API from "./API";

const prefix = "/api/user/userFlow";

export const getUserFlows = async () => {
  return await API.get(`${prefix}`);
};

export const getUserFlow = async (userFlowId) => {
  return await API.get(`${prefix}/${userFlowId}`);
};

export const createUserFlow = async (flowId, pointInTime) => {
  return await API.post(`${prefix}/${flowId}`, null, {
    params: {
      pointInTime: dayjs(pointInTime).set("hour", 12).toISOString(),
    },
  });
};

export const updateUserFlow = async (userFlowId, finished) => {
  return await API.put(`${prefix}/${userFlowId}`, null, {
    params: {
      finished: finished,
    },
  });
};






