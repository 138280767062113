import React, { useState } from "react";
import colors from "../utils/colors";
import keys from "../api/keys";
import { getProviderFlowPresets, getProviderFlows } from "../api/provider";
import { useQuery } from "react-query";
import { Recap } from "../utils/heimdall";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export default () => {
    const navigate = useNavigate();

    const [selectedFlowPreset, setSelectedFlowPreset] = useState();

    const { data: flowPresets } = useQuery([keys.PROVIDER_FLOW_PRESETS], () => getProviderFlowPresets());
    const { data: flows } = useQuery([keys.PROVIDER_FLOWS], () => getProviderFlows());

    const FlowPreset = ({ flowPreset }) => (
        <div onClick={() => setSelectedFlowPreset(flowPreset)}
            style={{
                borderRadius: 12,
                backgroundColor: "white",
                cursor: "pointer",
                padding: 30,
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between"
            }}
            className="shadow">
            <div style={{ color: colors.text }}>{flowPreset.name}</div>
            <div style={{ color: colors.grey }}>{`${dayjs(flowPreset.start).format("DD.MM.YYYY")} - ${dayjs(flowPreset.end).format("DD.MM.YYYY")}`}</div>
            <div style={{ color: colors.grey }}>{`${flowPreset.flowIds.length} Flows`}</div>
        </div>
    );

    if (!flowPresets) return <></>;

    return (<div>
        <div style={{ color: colors.text, fontSize: 36, marginBottom: 30 }}>Flow Templates</div>
        {flowPresets.map(x => {
            return <>
                <FlowPreset flowPreset={x} />
                {selectedFlowPreset?.id === x.id &&
                    <div>
                        <div style={{ marginTop: 10, fontWeight: "bold" }}>Flows</div>
                        {
                            flows.filter(x => selectedFlowPreset.flowIds.includes(x.id)).map(x => {
                                return <div style={{
                                    marginTop: 10,
                                    marginLeft: 25,
                                    borderRadius: 12,
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                    padding: 20,
                                }}
                                    onClick={() => navigate(`flow/${x.id}`)}
                                    className="shadow"
                                ><div>{`${x.recapId === Recap.CheckIn ? "Check-In" : "Check-Out"}`}</div></div>
                            })
                        }
                    </div>
                }
            </>
        })}
    </div>)
};