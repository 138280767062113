import React from "react";
import { Button } from "react-bootstrap";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";


export default ({ canGoBack = true }) => {
    const navigate = useNavigate();

    return (
        <Button
            style={{ borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: 10, height: 50, width: 50 }}
            onClick={canGoBack ? () => navigate(-1) : () => { }}
            variant="primary">
            <RiArrowLeftLine size={20} style={{ alignSelf: "center", justifySelf: "center" }} />
        </Button>);
};