import API from "./API";

const prefix = "/api/admin";

export const getProviderForAdmin = async () => {
    return await API.get(`${prefix}/provider`);
};

export const getUsersForAdmin = async () => {
    return await API.get(`${prefix}/user`);
};

export const createProvider = async (data) => {
    return await API.post(`${prefix}/provider`, data);
};





