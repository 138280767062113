import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import keys from "../api/keys";
import { getOwnUser } from "../api/user";


export function useUser() {
    const [user, setUser] = useState();

    const { data: _user } = useQuery([keys.OWN_USER], () => getOwnUser());

    useEffect(() => {
        setUser(_user);
    }, [_user]);

    return user;
};