import React, { useState } from "react";
import colors from "../utils/colors";
import keys from "../api/keys";
import { useMutation, useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import CreateButton from "../components/CreateButton";
import { Button, Col, Row } from "react-bootstrap";
import { getUserFlowDefinitions } from "../api/userFlowDefinitions";
import { getFlow } from "../api/flow";
import { useNavigate, useParams } from "react-router";
import GoBackButton from "../components/GoBackButton";
import { createUserFlow, getUserFlows } from "../api/userFlow";
import DayPicker from "../components/DayPicker";

export default () => {
    const navigate = useNavigate();
    const { userFlowDefinitionId } = useParams();

    const { data: userFlowDefinitions } = useQuery([keys.USER_FLOW_DEFINITIONS], () => getUserFlowDefinitions());
    const { data: userFlows } = useQuery([keys.USER_FLOWS], () => getUserFlows());

    const [showDayPicker, setShowDayPicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs().set("hours", 12).set("minutes", 0).set("millisecond", 0).toISOString())

    const userFlowsOfDay = userFlows?.filter(x => dayjs(x.pointInTime).isSame(selectedDate, "date"));
    const queryClient = useQueryClient();

    const { mutate: _createUserFlow } = useMutation(
        (flowId) => createUserFlow(flowId, selectedDate),
        {
            onSuccess: (data) => {
                navigate(`userFlow/${data.flowId}/${data.id}/${dayjs(selectedDate).toISOString()}`);
                queryClient.invalidateQueries([keys.USER_FLOWS]);
            },
            onError: (e) => {
                console.warn(e);
                alert('there was an error');
            },
        }
    );

    const Flow = ({ flow }) => {
        const { data: _flow } = useQuery([keys.FLOW, flow.id], () => getFlow(flow.id));
        if (!_flow) return;
        console.warn(_flow)
        return (
            <Row
                style={{
                    borderRadius: 12,
                    backgroundColor: "white",
                    padding: 30,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 10
                }}
                className="shadow">
            
                <Col style={{ color: colors.text }}>{_flow.id}</Col>
                <Col style={{ color: colors.grey, textAlign: "center" }}>{`${_flow.questions.filter(x => !x.deactivated).length} Fragen`}</Col>
                <Col style={{ color: colors.grey, textAlign: "right" }}>
                    <Button onClick={() => {!!!userFlowsOfDay?.find(x => x.flowId === _flow.id) ? _createUserFlow(_flow.id) : navigate(`userFlow/${_flow.id}/${userFlowsOfDay?.find(x => x.flowId === _flow.id).id}/${dayjs(selectedDate).toISOString()}`);}}>
                        {!!!userFlowsOfDay?.find(x => x.flowId === _flow.id) ? "Flow starten" : "Flow bearbeiten"}
                    </Button>
                </Col>
            </Row>
        )
    };

    const userFlowDefinition = userFlowDefinitions?.find(x => x.id === userFlowDefinitionId);

    if (!userFlowDefinition) return;

    return (<div>
        <div className="d-flex align-items-center" style={{ marginBottom: 30, justifyContent: "space-between" }}>
            <GoBackButton />
            <div style={{ position: "relative" }}>
            <div onClick={() => setShowDayPicker(!showDayPicker)} style={{ color: colors.text, fontSize: 36, cursor: "pointer" }}>
                Flows {dayjs(selectedDate).format("DD. MMMM YYYY")}
            </div>
            {showDayPicker && <DayPicker 
            onSelectionChanged={(date) => {
                setSelectedDate(dayjs(date).set("h", 12).toISOString());
                setShowDayPicker(false)
            }} 
            style={{ position: "absolute", left: -20 }}/>}
            </div>
            <CreateButton onClick={() => { }} title="Flowplan erstellen (Coming Soon)" />
        </div>
        {userFlowDefinition?.flows?.map(x => <Flow flow={x} />)}
    </div>)
};