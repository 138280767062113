import React, { useState } from "react";
import colors from "../utils/colors";
import keys from "../api/keys";
import { useMutation, useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import CreateButton from "../components/CreateButton";
import { createFile, getFiles } from "../api/file";
import { Col, Form, Row } from "react-bootstrap";
import Card from "../components/Card";
import { getContentUrl } from "../utils/firebaseHelper";

export default () => {
    const [file, setFile] = useState();
    const [title, setTitle] = useState('');
    const [showAddFile, setShowAddFile] = useState();

    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation(
        () => createFile(title, file),
        {
            onSuccess: (_) => {
                setShowAddFile(false);
                queryClient.invalidateQueries(keys.SUPER_ADMIN_FILES);
            },
            onError: (e) => {
                console.warn(e);
                alert('there was an error');
            },
        }
    );

    const { data: videos } = useQuery([keys.SUPER_ADMIN_FILES], () => getFiles());

    const Video = ({ video }) => (
        <Row
            style={{
                borderRadius: 12,
                backgroundColor: "white",
                cursor: "pointer",
                padding: 30,
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10
            }}
            onClick={() => { }}
            className="shadow">
            <Col>{video.title ? video.title : "Kein Titel"}</Col>
            <Col>
                <video style={{ height: 150, width: "100%" }} controls>
                    <source src={getContentUrl(video.id)} type="video/mp4"></source>
                </video>
            </Col>
            <Col style={{ color: colors.grey, textAlign: "right" }}>{`Hochgeladen am: ${dayjs(video.created).format("DD.MM.YYYY")}`}</Col>
        </Row>
    );

    if (!videos) return;

    return (<div>
        <div className="d-flex align-items-center" style={{ marginBottom: 30, justifyContent: "space-between" }}>
            <div style={{ color: colors.text, fontSize: 36, }}>Content</div>
            <CreateButton onClick={() => setShowAddFile(!showAddFile)} title="Content hinzufügen" />
        </div>
        {showAddFile && <Card style={{ flexDirection: "row", display: "flex", alignItems: "center", width: "100%", marginBottom: 24 }}>
            <div style={{ marginLeft: 16, marginRight: 16 }}>Content</div>
            <Form.Group style={{ marginLeft: 8, height: 42 }}>
                <Form.Control
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    style={{ height: 42 }}
                    placeholder="Name"
                />
            </Form.Group>
            <Form.Group style={{ marginLeft: 8, height: 42, marginRight: 32 }}>
                <Form.Control
                    onChange={(e) => setFile(e.target.files[0])}
                    value={''}
                    style={{ height: 42 }}
                    placeholder="Content auswählen"
                    type="file"
                />
            </Form.Group>
            <CreateButton title="Content hinzufügen" onClick={mutate} loading={isLoading} />
        </Card>}
        {videos.map(x => <Video video={x} />)}
    </div>)
};