import React, { useState } from "react";
import colors from "../utils/colors";
import { deleteQuestionAtProvider, getDetailedFlow } from "../api/provider";
import keys from "../api/keys";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import GoBackButton from "../components/GoBackButton";
import { QuestionTypesReversed } from "../utils/heimdall";
import CreateButton from "../components/CreateButton";
import CreateQuestion from "./CreateQuestion";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Col, Row } from "react-bootstrap";
import { toast } from "../utils/toast";
import { getOwnUser } from "../api/user";

export default () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { data: flow } = useQuery([keys.PROVIDER_FLOWS, id], () => getDetailedFlow(id), { enabled: !!id });
    const { data: user } = useQuery([keys.OWN_USER], () => getOwnUser());

    const [questionInCreation, setQuestionInCreation] = useState(false);

    const queryClient = useQueryClient();

    const { mutate: deleteQuestion } = useMutation(
        (questionId) => deleteQuestionAtProvider(user.providerId, questionId, flow.id),
        {
            onSuccess: (data) => {
                if (data?.errors?.length > 0 && data.errors[0].errorMessage === "answers_exist_already_at_question") {
                    toast.info("Die Frage wurde nur deaktiviert, da es bereits aktive Antworten gab. Die Frage ist nur noch einsehbar für die Vergangenheit, wird dem User aber nicht mehr angezeigt.");
                }

                queryClient.invalidateQueries([keys.PROVIDER_FLOWS, id]);
            },
            onError: (e) => {
                console.warn(e);
                alert('there was an error');
            },
        }
    );

    const Question = ({ question }) => (
        <Row
            style={{
                borderRadius: 12,
                backgroundColor: "white",
                cursor: "pointer",
                padding: 30,
                marginBottom: 10,
                width: "100%"
            }}
            className="shadow"
            onClick={() => navigate(`flow/${id}/question/${question.id}`)}>
            <Col md={7} style={{ color: colors.text }}>{`${question.text}`}</Col>
            <Col md={3} style={{ color: colors.grey, textAlign: "center" }}>{`${QuestionTypesReversed[question.questionTypeId]}`}</Col>
            <Col style={{ textAlign: "right" }} md={2}>
                {!question.deactivated ? <RiDeleteBin5Line className="delete" size={24}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        deleteQuestion(question.id);
                    }} /> : <div style={{ color: "#F53D6B" }}>Deaktiviert</div>}
            </Col>
        </Row>
    );

    if (!flow) return;

    return (<div>
        <div className="d-flex align-items-center" style={{ marginBottom: 30, justifyContent: "space-between" }}>
            <div className="d-flex align-items-center flex-direction-row">
                <GoBackButton />
                <div style={{ color: colors.text, fontSize: 36, marginLeft: 16 }}>Flow</div>
            </div>
            {!questionInCreation && <CreateButton onClick={() => setQuestionInCreation(true)} title="Frage hinzufügen" />}
        </div>
        {questionInCreation && <div style={{ marginBottom: 30 }}>
            <CreateQuestion flowId={id} onCreated={() => setQuestionInCreation(false)} />
        </div>}

        {flow.questions.sort((a, b) => a.order - b.order).map((x) => <Question question={x} />)}
        {flow.questions.length === 0 && <div>Keine Fragen</div>}

    </div>)
};