import React from "react";
import colors from "../utils/colors";
import { getProviderForAdmin, getUsersForAdmin } from "../api/admin";
import keys from "../api/keys";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import CreateButton from "../components/CreateButton";
import { useNavigate, useParams } from "react-router-dom";
import { Roles, RolesReversed } from "../utils/heimdall";
import { Row, Col } from "react-bootstrap";

export default () => {
    const navigate = useNavigate();
    const { providerId } = useParams();

    const { data: providers } = useQuery([keys.SUPER_ADMIN_PROVIDERS], () => getProviderForAdmin(), { enabled: !!providerId });

    const provider = providers?.find(x => x.id === providerId);

    const User = ({ user }) => (
        <Row
            style={{
                borderRadius: 12,
                backgroundColor: "white",
                cursor: "pointer",
                padding: 30,
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10
            }}
            className="shadow">
            <Col style={{ color: colors.text }}>{`${user.firstName} ${user.lastName}`}</Col>
            <Col style={{ color: colors.grey }}>{`${user.email}`}</Col>
            <Col style={{ color: colors.grey }}>{`${user.roleId === Roles.Admin ? "Admin" : "Member"}`}</Col>
            <Col style={{ color: colors.grey, textAlign: "right" }}>{`Beigetreten am: ${dayjs(user.created).format("DD.MM.YYYY")}`}</Col>
        </Row>
    );

    const { data: users } = useQuery([keys.SUPER_ADMIN_USERS], () => getUsersForAdmin());
    if (!users) return;

    const providerUsers = users.filter(x => x.providerId === providerId);

    return (<div>
        <div className="d-flex align-items-center" style={{ marginBottom: 30, justifyContent: "space-between" }}>
            <div style={{ color: colors.text, fontSize: 36, }}>{provider.name}</div>
            <CreateButton onClick={() => navigate(`create-user/${providerId}`)} title="Benutzer anlegen" />
        </div>
        {providerUsers.length === 0 && <div>Keine Benutzer</div>}
        {providerUsers.map((x) => <User user={x} />)}
    </div>)
};