import React, { useState } from "react";
import colors from "../utils/colors";
import { createAnswerAtProvider, deleteAnswerAtProvider, getDetailedFlow, updateQuestionPropertyAtProvider } from "../api/provider";
import keys from "../api/keys";
import { QueryClient, useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import GoBackButton from "../components/GoBackButton";
import { QuestionPropertiesReversed, QuestionTypesReversed, QuestionTypes, AnswerTypes } from "../utils/heimdall";
import Card from "../components/Card";
import { AnswerProperties } from "../utils/heimdall";
import { getOwnUser } from "../api/user";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import symbolProviders from "../utils/symbolProviders";
import CreateButton from "../components/CreateButton";
import { RiDeleteBin5Line } from "react-icons/ri";
import { toast } from "../utils/toast";
import SelectAnswers from "../components/SelectAnswers";

export default () => {
    const { id: questionId, flowId } = useParams();
    const [symbol, setSymbol] = useState({ SymbolProvider: { ProviderName: '' }, Symbol: '' });
    const [symbolText, setSymbolText] = useState('');

    const [iconInCreation, setIconInCreation] = useState(false);

    const { data: user } = useQuery([keys.OWN_USER], () => getOwnUser());
    const { data: flow, refetch } = useQuery([keys.PROVIDER_FLOWS, flowId], () => getDetailedFlow(flowId), { enabled: !!flowId });

    const queryClient = new QueryClient();

    const { mutate, isLoading } = useMutation(
        () => createAnswerAtProvider(
            {
                answerTypeId: AnswerTypes.Symbol,
                text: symbolText,
                propertyId: AnswerProperties.AnswerSymbol,
                propertyValue: JSON.stringify(symbol),
                questionId: question.id,
                providerId: user.providerId
            }),
        {
            onSuccess: (_) => {
                setIconInCreation(false);
                setSymbol({ SymbolProvider: { ProviderName: '' }, Symbol: '' });
                queryClient.invalidateQueries([keys.PROVIDER_FLOWS, flowId]);
            },
            onError: (e) => {
                console.warn(e);
                alert('there was an error');
            },
        }
    );

    const { mutate: deleteAnswer, isLoading: deleteAnswerLoading } = useMutation(
        (answerId) => deleteAnswerAtProvider(user.providerId, answerId),
        {
            onSuccess: (data) => {
                if (data?.errors?.length > 0 && data.errors[0].errorMessage === "answers_exist_deletion_would_cause_data_loss") {
                    toast.error("Eine Antwort eines Users existiert bereits, Löschung nicht mehr möglich.");
                }
            },
            onError: (e) => {
                alert("ERROR");
                console.warn(e);
                alert('there was an error');
            },
        }
    );

    const { mutate: updateProperty } = useMutation(
        (data) => {
            updateQuestionPropertyAtProvider(
                question.id,
                data?.propertyId,
                {
                    value: data?.value.toString(),
                    providerId: user.providerId
                }
            )
        },
        {
            onSuccess: (_) => {
                refetch();
            },
            onError: (e) => {
                console.warn(e);
                alert('there was an error');
            },
        }
    );

    const onPropertyValueSaved = (propertyId, value) => {
        updateProperty({ propertyId, value });
    };

    if (!flow) return;

    const question = flow.questions.find(x => x.id === questionId);

    const IconProviderSelection = () => (
        <DropdownButton id="dropdown-basic-button" title={symbol.SymbolProvider?.ProviderName ? symbol.SymbolProvider?.ProviderName : "Auswählen"}>
            {symbolProviders.map(x => <Dropdown.Item onClick={() => setSymbol({ ...symbol, SymbolProvider: { ProviderName: x } })}>{x}</Dropdown.Item>)}
        </DropdownButton>
    );

    return (<div>
        <div className="d-flex align-items-center " style={{ marginBottom: 30, justifyContent: "space-between" }}>
            <div className="d-flex flex-direction-row">
                <GoBackButton />
                <div style={{ color: colors.text, fontSize: 36, marginLeft: 16 }}>Question - {question.text}</div>
            </div>
            <div style={{ fontSize: 20, fontWeight: 600, color: colors.grey }}>{QuestionTypesReversed[question.questionTypeId]}</div>
        </div>

        <div style={{ fontWeight: "bold" }}>Properties</div>
        {question?.properties?.length === 0 && <div>Keine</div>}
        {question?.properties?.length > 0 && <div style={{ display: "flex", flexDirection: "row", marginTop: 30 }}>
            {question.properties?.map(x => <Card onValueSaved={(value) => onPropertyValueSaved(x.id, value)} editable title={QuestionPropertiesReversed[x.id]} value={x.value} />)}
        </div>}
        <div className="d-flex flex-direction-row" style={{ marginTop: 30, alignItems: "center" }}>
            <div style={{ fontWeight: "bold", marginRight: 16 }}>Answers</div>
            {!iconInCreation && question.questionTypeId === QuestionTypes.Select && <CreateButton title="Icon hinzufügen" onClick={() => setIconInCreation(true)} />}
        </div>
        {iconInCreation && <Card style={{ flexDirection: "row", display: "flex", alignItems: "center", marginTop: 16 }}>
            <div style={{ marginRight: 16 }}>Icon</div>
            <IconProviderSelection />
            <Form.Group style={{ marginLeft: 8, height: 42 }}>
                <Form.Control
                    onChange={(e) => setSymbol({ ...symbol, Symbol: e.target.value })}
                    value={symbol.Symbol}
                    style={{ height: 42 }}
                    placeholder="Name"

                />
            </Form.Group>
            <Form.Group style={{ marginLeft: 8, height: 42, marginRight: 32 }}>
                <Form.Control
                    onChange={(e) => setSymbolText(e.target.value)}
                    value={symbolText}
                    style={{ height: 42 }}
                    placeholder="Text" />
            </Form.Group>
            <CreateButton title="Icon hinzufügen" onClick={mutate} loading={isLoading} />
        </Card>}
        {question?.answers?.length === 0 && <div>Keine</div>}
        {question.questionTypeId === QuestionTypes.Select && question?.answers &&
            <SelectAnswers answers={question?.answers} onDelete={(id) => deleteAnswer(id)} />}
    </div>)
};