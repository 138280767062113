import React from "react";
import colors from "../utils/colors";
import { Routes, Route } from "react-router-dom";
import { RiStackLine, RiLogoutCircleRLine, RiDashboard3Line, RiEmotionLine, RiTeamLine } from "react-icons/ri";
import { useUser } from "../hooks/useUser";
import Users from "../shared/Users";
import FlowTemplates from "./FlowTemplates";
import Overview from "./Overview";
import CreateUser from "../shared/CreateUser";
import Flow from "./Flow";
import Question from "./Question";
import Menu from "../components/Menu";

const menuItems = [
    {
        path: "/",
        title: "Dashboard",
        icon: (color) => <RiDashboard3Line size={24} color={color} />
    },
    {
        path: "/users",
        title: "User",
        icon: (color) => <RiTeamLine size={24} color={color} />
    },
    {
        path: "/flow-templates",
        title: "Flow Templates",
        icon: (color) => <RiStackLine size={24} color={color} />
    },
    {
        path: "/icons",
        title: "Icons",
        icon: (color) => <RiEmotionLine size={24} color={color} />
    },
];

export default () => {
    const user = useUser();

    const onLogout = () => {
        localStorage.removeItem("token");
        window.location.href = "/";
    };

    if (!user) return <></>;

    return (
        <div style={{ height: "100vh", flexDirection: "row", display: "flex" }}>
            <div style={{ padding: 36, width: "20%", height: "100%" }}>
                <div style={{ flexDirection: "row", display: "flex" }}>
                    <div style={{ marginRight: 15 }}>
                        <img src={require("../assets/icon.png")} height={36} />
                    </div>
                    <div style={{ fontSize: 24, fontWeight: "600" }}>IO Admin</div>
                </div>
                <div style={{ marginTop: 70 }}>
                    <div style={{ color: colors.grey, fontSize: 12, marginBottom: 16 }}>Admin Panel</div>
                    <div>
                       <Menu menuItems={menuItems}/>
                    </div>
                </div>
                <img className="noselect" src={require("../assets/big_fingerprint.png")} style={{ position: "absolute", bottom: 0, left: 0, width: "75%", zIndex: -1 }} />
            </div>
            <div style={{ width: "80%", height: "100%" }}>
                <div style={{ height: "100px", backgroundColor: "white", display: "flex", alignItems: "center", paddingLeft: 30, paddingRight: 30 }}>
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <div style={{ color: colors.text, fontSize: 14, fontWeight: "bold", justifySelf: "flex-end" }}>{`${user.firstName} ${user.lastName}`}</div>
                        <RiLogoutCircleRLine onClick={onLogout} style={{ cursor: "pointer", marginLeft: 16 }} size={24} color={colors.text} />
                    </div>
                </div>
                <div style={{ height: "calc(100% - 100px)", backgroundColor: colors.lightGreen, zIndex: 2, paddingLeft: 30, paddingRight: 30, paddingTop: 30, paddingBottom: 50 }}>
                    <Routes>
                        <Route path="/" element={<Overview />} />
                        <Route path="users" element={<Users />} />
                        <Route path="flow-templates" element={<FlowTemplates />} />
                        <Route path="create-user" element={<CreateUser />} />
                        <Route path="flow/:id" element={<Flow />} />
                        <Route path="/flow/:flowId/question/:id" element={<Question />} />
                        <Route path="/icons" element={<iframe style={{ width: "100%", height: "100%" }} src="https://icons.expo.fyi/"></iframe>} />
                    </Routes>
                </div>
            </div>
        </div >
    );
};